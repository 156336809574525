"use strict";

const { over } = require("lodash");
import IMask from 'imask';

document.addEventListener('DOMContentLoaded', (e) => {

    //hamburger

    const hamburger = document.querySelector('.hamburger'),
          navMenu = document.querySelector('.nav-menu'),
          navMenuItem = document.querySelectorAll('.nav-menu__item');
        
    hamburger.addEventListener('click', (e) => {
        navMenu.classList.toggle('nav-menu_active');
        hamburger.classList.toggle('hamburger_active');
    });

    navMenuItem.forEach(item => {
        item.addEventListener('click', (e) => {
            navMenu.classList.toggle('nav-menu_active');
            hamburger.classList.toggle('hamburger_active');
        });
    })
    
    //slider

    const slidesWrapper = document.querySelector('.product__wrapper'),
          slider = slidesWrapper.querySelector('.product__slider'),
          slides = slider.querySelectorAll('.product__slide'),
          circles = document.querySelectorAll('.product-circles__round'),
          width = window.getComputedStyle(slidesWrapper).width;

    let slideIndex = 1,
        offset = 0;

    slider.style.width = 100 * slides.length + '%';
    slider.style.transition = '1.5s all';
    slides.forEach(slide => {
        slide.style.width = width;
    });

    function rollSlider(index) {
        offset = +width.slice(0, width.length - 2) * index;
        slider.style.transform = `translateX(-${offset}px)`;
    }

    function thisSlide(index) {
        circles.forEach(item => {
            item.classList.remove('product-circles__round_active');
        })
        circles[index].classList.add('product-circles__round_active');
        slides.forEach(item => {
            item.classList.remove('product__slide_active');
        });
        slides[index].classList.add('product__slide_active');
    }

    let autoSlide = setInterval(() => {
        nextSlide();
    }, 4000);

    function nextSlide(index = slideIndex) {
        slideIndex++;
        if (slideIndex >= slides.length) {
            slideIndex = 0;
        }
        rollSlider(index);
        thisSlide(index);
    }

    circles.forEach((item, index) => {
        item.addEventListener('click', (e) => {
            slideIndex = index;
            clearInterval(autoSlide);
            nextSlide(index);
            autoSlide = setInterval(() => {
                nextSlide();
            }, 8000);
        })
    });
    
//modal

    const modalTriggerConsultation = document.querySelector('[data-modal="consultation"]'),
          overlay = document.querySelector('.overlay'),
          modalConsultation = document.querySelector('#modal__consultation'),
          modalCloseBtn = document.querySelectorAll('[data-close]'),
          confidentialTrigger = document.querySelectorAll('.confidential'),
          modalConfidential = document.querySelector('.modal-confidential'),
          inputs = document.querySelectorAll('.form__input_req');


    function openModal(modal = modalConsultation) {
        modal.style.opacity = 0;
        setTimeout(() => {
            const blockAppearance = setInterval(() => {
                modal.style.opacity = parseFloat(modal.style.opacity) + 0.05;
                if (modal.style.opacity >= 1) {
                    clearInterval(blockAppearance);
                }
            }, 20);
        }, 100)
        overlay.classList.add('show');
        overlay.classList.remove('hide');
        modal.classList.add('show');
        modal.classList.remove('hide');
        document.body.style.overflow = 'hidden';
        clearInterval(modalTimerId);
    };

    function closeModal(parent) {
        overlay.classList.add('hide');
        overlay.classList.remove('show');
        parent.classList.add('hide');
        parent.classList.remove('show');
        document.body.style.overflow = '';
        inputs.forEach(item => {
            item.classList.remove('_error');
        });
    };

    modalTriggerConsultation.addEventListener('click', () => {
        openModal(modalConsultation);
    });
 
    confidentialTrigger.forEach(item => {
        item.addEventListener('click', (e) => {
            closeModal(modalConsultation);
            openModal(modalConfidential);
        });
    });

    overlay.addEventListener('click', (e) => {
        if (e.target === overlay || e.target.getAttribute('data-close') == '') {
            modalCloseBtn.forEach(item => {
                closeModal(item.parentElement);
            });
        }
    });

    const modalTimerId = setTimeout(openModal, 20000);

    //spheres

    const spheresItems = document.querySelectorAll('.spheres__item'),
          spheresDescription = document.querySelectorAll('.spheres__descr-wrapper');

    function showDescriptionSpheres(item) {
        item.classList.add('active');
        item.style.opacity = 0;
        setTimeout(() => {
            const blockAppearance = setInterval(() => {
                item.style.opacity = parseFloat(item.style.opacity) + 0.05;
                if (item.style.opacity >= 1) {
                    clearInterval(blockAppearance);
                }
            }, 20);
        }, 100)
    };

    function closeDescriptionSpheres(item) {
        item.style.opacity = 1;
        setTimeout(() => {
            const blockDisappearing = setInterval(() => {
                item.style.opacity = parseFloat(item.style.opacity) - 0.05;
                if (item.style.opacity <= 0) {
                    clearInterval(blockDisappearing);
                    item.classList.remove('active');
                    item.classList.add('none');
                }
            }, 20);
        }, 100);
    };

    function scrollToBlock(item) {
        item.scrollIntoView();
    };

    spheresItems.forEach((item, i) => {
        item.addEventListener('click', (e) => {
            if (item.classList.contains('active')) {
                ;
            } else {
                spheresItems.forEach(item => {
                    item.classList.remove('active');
                });
                item.classList.add('active');
                spheresDescription.forEach(item => {
                    if (item.classList.contains('active')) {
                        closeDescriptionSpheres(item);
                    }
                })
                spheresDescription.forEach(item => {
                    item.classList.remove('active');
                    item.classList.add('none');
                })
                showDescriptionSpheres(spheresDescription[i]);
                if (window.screen.width <= 991) {
                    scrollToBlock(spheresDescription[i]);
                }
            }
        });
    });


    //inputs

    const loading = document.querySelector('.feed-form__loading'),
          finished = document.querySelector('.finished'),
          feedForms = document.querySelectorAll('.feed-form'),
          phoneInputs = document.querySelectorAll('.form__input_tel');

    phoneInputs.forEach(phoneInput => {
        const phoneMask = new IMask(phoneInput, {
            mask: "+{7}(000)000-00-00",
        });
    });


    feedForms.forEach(item => {
        item.addEventListener('submit', (e) => {
            e.preventDefault();
            sendForm(item);
        });
    });


    async function sendForm(form) {

        let error = formValidate(form);

        let formData = new FormData(form);

        if (error === 0) {
            loading.classList.add('show');
            loading.classList.remove('hide');
            overlay.classList.add('show');
            overlay.classList.remove('hide');
            let response = await fetch('sendmail.php', {
                method: 'POST',
                body: formData
            });
            if (response.ok) {
                let result = await response.json();
                loading.classList.remove('show');
                loading.classList.add('hide');
                form.reset();
                overlay.classList.add('show');
                overlay.classList.remove('hide');
                finished.classList.remove('hide');
                finished.classList.add('show');
            }
        }
    }

    function formValidate(form) {
        let error = 0;
        let formReq = form.querySelectorAll('.form__input_req');

        for (let index = 0; index < formReq.length; index++) {
            const input = formReq[index];
            formRemoveError(input);

            if (input.classList.contains('form__input_email')) {
                if (emailTest(input)) {
                    formAddError(input);
                    error++;
                }
            } else if (input.getAttribute("type") === "checkbox" && input.checked === false) {
                formAddError(input.nextElementSibling);
                error++;
            } else if (input.getAttribute("type") === "tel" && input.value.length <= 15) {
                formAddError(input);
                error++;
            } else {
                if (input.value === '') {
                    formAddError(input);
                    error++;
                } else if (input.value.length <= 1) {
                    formAddError(input);
                    error++;
                }
            }
        }
        return error;
    }

    function formAddError(input) {
        input.parentElement.classList.add('_error');
        input.classList.add('_error');
    }
    function formRemoveError(input) {
        if (input.getAttribute("type") === "checkbox") {
            input.parentElement.classList.remove('_error');
            input.nextElementSibling.classList.remove('_error');
        } else {
            input.parentElement.classList.remove('_error');
            input.classList.remove('_error');
        }
        
    }

    function emailTest(input) {
        return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(input.value);
    }
    
    
});



